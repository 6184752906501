import { ComponentType } from 'react';

import { LinkLocalized } from '~/app/lib/utils/resolveLinksForCountry/lib/localizeLinks';
import { ItemPageCustomLinkObject } from '~/app/lib/songwhipApi/types';
import { ResolvedServiceData } from '~/app/lib/getServiceDisplayData';
import { ServiceTypes } from '~/types';
import { Icon } from '~/app/components/Icon/toIcon';

import { LayoutData } from '../types';
import { IconKey } from './lib/icons';

export type ItemLinksCustomLink = ItemPageCustomLinkObject;
export type ItemLinksDefaultLink = LinkLocalized;

export interface PageSectionBaseProps {
  /**
   * A path to identify the section definition in the page's `layout`.
   */
  sectionPath: string;

  /**
   * An unique identified for this section on the page.
   * Used for ItemPageNav to jump to specific section.
   */
  sectionId: string;

  /**
   * The index of the section in relation to its sibling sections.
   */
  sectionIndex: number;

  /**
   * A short one-word title to show in the ItemPageNav.
   * If falsy no nav item will be shown for the section.
   */
  navTitle?: string;

  layoutData: LayoutData;
  isShared?: boolean;

  /**
   * Indicates if <PageSections> has grouped a section with
   * another for aesthetic purposes.
   */
  isGrouped?: boolean;
}

// using `ComponentType` so compatible with next/dynamic
export type PageSectionComponent<TProps = {}> = ComponentType<
  TProps & PageSectionBaseProps
> & {
  shouldGroup?: (params: {
    props: Omit<TProps, 'isGrouped'>;
    prevSection: { type: PageSectionTypes };
  }) => boolean;
};

export interface ResolvedLink {
  type: 'link';
  link: string;
  text: string;
  Icon: Icon | undefined;
  icon: IconKey | undefined;
  isDefault: boolean;
  serviceType: ServiceTypes;
  service: ResolvedServiceData;
  dataPath: string;
  pagePeeking?: boolean;
}

export enum PageSectionTypes {
  PAGE_TITLE = 'PageTitle',
  ITEM_LINKS = 'ItemLinks',
  ICON_LINKS = 'IconLinks',
  BLOCK_TEXT = 'BlockText',
  IMAGE_BACKGROUND = 'ImageBackground',
  PRESAVE_BUTTONS = 'PresaveButtons',
  PRESAVE_BUTTONS_2 = 'PresaveButtons2',
  VIDEOS = 'Videos',
  SHOWS = 'Shows',
  MERCH = 'Merch',
  RELEASES = 'Releases',
  CAROUSEL = 'Carousel',
  TITLE_LINKS = 'TitleLinks',
  INLINE_IMAGE = 'INLINE_IMAGE',
}
