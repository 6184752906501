import { FC } from 'react';

import Clickable, { ClickableProps } from '~/app/components/Clickable';
import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import toSizedImageUrlNext from '~/app/lib/toSizedImageUrlNext';
import Gradient from '~/app/components/Gradient';
import Image from '~/app/components/Image';
import Text from '~/app/components/Text';

import { usePageTheme } from '../../../../ItemPageEdit/addons/theme/PageThemeContext';

const CarouselSectionItem: FC<
  { text?: string; image: string } & ClickableProps
> = ({ text, image, ...clickableProps }) => {
  const isLargeScreen = useIsLargeScreen();
  const pageTheme = usePageTheme();

  return (
    <Clickable
      {...clickableProps}
      fullHeight
      positionRelative
      withActiveStyle={false}
      testId="carouselItem"
      withHoverOpacityFrom={0.9}
      style={{
        paddingBottom: isLargeScreen ? '92%' : '108%',
        userSelect: 'none',
      }}
    >
      <Image
        src={toSizedImageUrlNext({
          url: image,
          width: isLargeScreen ? 1000 : 800,
          quality: 60,
        })}
        fillParent
        isLazy
        cover
        style={{
          position: 'absolute',
          background: pageTheme.backgroundColor,
        }}
        imgStyle={{
          userSelect: 'none',
          filter: 'saturate(.8) brightness(.95)',
        }}
      />
      <Gradient
        to="rgba(0,0,0,0.34)"
        positionAbsolute
        bottom={0}
        left={0}
        right={0}
        zIndex={2}
        height="50%"
        padding="2rem"
        flexBox
      >
        <Text
          centered
          margin="auto 0 0"
          fullWidth
          size="1.7rem"
          weight="bold"
          letterSpacing={0.01}
          className="text"
          withEllipsis
          color="#fff"
          shadow="0 0 .3em rgba(0,0,0,0.4)"
          opacity={0.9}
          family={pageTheme.fontFamily}
        >
          {text}
        </Text>
      </Gradient>
    </Clickable>
  );
};

export default CarouselSectionItem;
