export const AFGHANISTAN = 'AF';
// export const ALAND_ISLANDS = 'AX';
export const ALBANIA = 'AL';
// export const ALGERIA = 'DZ';
// export const AMERICAN_SAMOA = 'AS';
// export const ANDORRA = 'AD';
// export const ANGOLA = 'AO';
// export const ANGUILLA = 'AI';
// export const ANTARCTICA = 'AQ';
// export const ANTIGUA_AND_BARBUDA = 'AG';
export const ARGENTINA = 'AR';
// export const ARMENIA = 'AM';
// export const ARUBA = 'AW';
export const AUSTRALIA = 'AU';
export const AUSTRIA = 'AT';
// export const AZERBAIJAN = 'AZ';
// export const BAHAMAS = 'BS';
export const BAHRAIN = 'BH';
// export const BANGLADESH = 'BD';
// export const BARBADOS = 'BB';
export const BELARUS = 'BY';
export const BELGIUM = 'BE';
// export const BELIZE = 'BZ';
// export const BENIN = 'BJ';
// export const BERMUDA = 'BM';
// export const BHUTAN = 'BT';
// export const BOLIVIA = 'BO';
// export const BONAIRE_SINT_EUSTATIUS_SABA = 'BQ';
export const BOSNIA_AND_HERZEGOVINA = 'BA';
// export const BOTSWANA = 'BW';
// export const BOUVET_ISLAND = 'BV';
export const BRAZIL = 'BR';
// export const BRITISH_INDIAN_OCEAN_TERRITORY = 'IO';
// export const BRUNEI_DARUSSALAM = 'BN';
export const BULGARIA = 'BG';
// export const BURKINA_FASO = 'BF';
// export const BURUNDI = 'BI';
// export const CAMBODIA = 'KH';
// export const CAMEROON = 'CM';
export const CANADA = 'CA';
// export const CAPE_VERDE = 'CV';
// export const CAYMAN_ISLANDS = 'KY';
export const CENTRAL_AFRICAN_REPUBLIC = 'CF';
// export const CHAD = 'TD';
export const CHILE = 'CL';
export const CHINA = 'CN';
// export const CHRISTMAS_ISLAND = 'CX';
// export const COCOS_KEELING_ISLANDS = 'CC';
export const COLOMBIA = 'CO';
// export const COMOROS = 'KM';
// export const CONGO = 'CG';
export const CONGO_DEMOCRATIC_REPUBLIC = 'CD';
// export const COOK_ISLANDS = 'CK';
// export const COSTA_RICA = 'CR';
export const COTE_D_IVOIRE = 'CI';
export const CROATIA = 'HR';
export const CUBA = 'CU';
// export const CURACAO = 'CW';
// export const CYPRUS = 'CY';
// export const CZECH_REPUBLIC = 'CZ';
export const DENMARK = 'DK';
// export const DJIBOUTI = 'DJ';
// export const DOMINICA = 'DM';
// export const DOMINICAN_REPUBLIC = 'DO';
// export const ECUADOR = 'EC';
export const EGYPT = 'EG';
// export const EL_SALVADOR = 'SV';
// export const EQUATORIAL_GUINEA = 'GQ';
// export const ERITREA = 'ER';
// export const ESTONIA = 'EE';
export const ETHIOPIA = 'ET';
// export const FALKLAND_ISLANDS = 'FK';
// export const FAROE_ISLANDS = 'FO';
// export const FIJI = 'FJ';
export const FINLAND = 'FI';
export const FRANCE = 'FR';
// export const FRENCH_GUIANA = 'GF';
// export const FRENCH_POLYNESIA = 'PF';
// export const FRENCH_SOUTHERN_TERRITORIES = 'TF';
// export const GABON = 'GA';
// export const GAMBIA = 'GM';
// export const GEORGIA = 'GE';
export const GERMANY = 'DE';
// export const GHANA = 'GH';
// export const GIBRALTAR = 'GI';
// export const GREECE = 'GR';
// export const GREENLAND = 'GL';
// export const GRENADA = 'GD';
// export const GUADELOUPE = 'GP';
// export const GUAM = 'GU';
// export const GUATEMALA = 'GT';
// export const GUERNSEY = 'GG';
// export const GUINEA = 'GN';
// export const GUINEA_BISSAU = 'GW';
// export const GUYANA = 'GY';
// export const HAITI = 'HT';
// export const HEARD_ISLAND_MCDONALD_ISLANDS = 'HM';
// export const HOLY_SEE_VATICAN_CITY_STATE = 'VA';
// export const HONDURAS = 'HN';
export const HONG_KONG = 'HK';
export const HUNGARY = 'HU';
// export const ICELAND = 'IS';
export const INDIA = 'IN';
export const INDONESIA = 'ID';
export const IRAN = 'IR';
export const IRAQ = 'IQ';
export const IRELAND = 'IE';
// export const ISLE_OF_MAN = 'IM';
export const ISRAEL = 'IL';
export const ITALY = 'IT';
// export const JAMAICA = 'JM';
// export const JAPAN = 'JP';
// export const JERSEY = 'JE';
export const JORDAN = 'JO';
// export const KAZAKHSTAN = 'KZ';
// export const KENYA = 'KE';
// export const KIRIBATI = 'KI';
export const KOREA = 'KR';
export const KOREA_DEMOCRATIC_PEOPLES_REPUBLIC = 'KP';
export const KUWAIT = 'KW';
// export const KYRGYZSTAN = 'KG';
// export const LAO_PEOPLES_DEMOCRATIC_REPUBLIC = 'LA';
// export const LATVIA = 'LV';
export const LEBANON = 'LB';
// export const LESOTHO = 'LS';
// export const LIBERIA = 'LR';
export const LIBYAN_ARAB_JAMAHIRIYA = 'LY';
// export const LIECHTENSTEIN = 'LI';
// export const LITHUANIA = 'LT';
export const LUXEMBOURG = 'LU';
// export const MACAO = 'MO';
export const MACEDONIA = 'MK';
// export const MADAGASCAR = 'MG';
// export const MALAWI = 'MW';
export const MALAYSIA = 'MY';
// export const MALDIVES = 'MV';
export const MALI = 'ML';
// export const MALTA = 'MT';
// export const MARSHALL_ISLANDS = 'MH';
// export const MARTINIQUE = 'MQ';
// export const MAURITANIA = 'MR';
// export const MAURITIUS = 'MU';
// export const MAYOTTE = 'YT';
export const MEXICO = 'MX';
// export const MICRONESIA = 'FM';
// export const MOLDOVA = 'MD';
// export const MONACO = 'MC';
// export const MONGOLIA = 'MN';
export const MONTENEGRO = 'ME';
// export const MONTSERRAT = 'MS';
// export const MOROCCO = 'MA';
// export const MOZAMBIQUE = 'MZ';
export const MYANMAR = 'MM';
// export const NAMIBIA = 'NA';
// export const NAURU = 'NR';
// export const NEPAL = 'NP';
export const NETHERLANDS = 'NL';
// export const NEW_CALEDONIA = 'NC';
export const NEW_ZEALAND = 'NZ';
export const NICARAGUA = 'NI';
// export const NIGER = 'NE';
// export const NIGERIA = 'NG';
// export const NIUE = 'NU';
// export const NORFOLK_ISLAND = 'NF';
// export const NORTHERN_MARIANA_ISLANDS = 'MP';
export const NORWAY = 'NO';
export const OMAN = 'OM';
// export const PAKISTAN = 'PK';
// export const PALAU = 'PW';
// export const PALESTINIAN_TERRITORY = 'PS';
// export const PANAMA = 'PA';
// export const PAPUA_NEW_GUINEA = 'PG';
// export const PARAGUAY = 'PY';
export const PERU = 'PE';
export const PHILIPPINES = 'PH';
// export const PITCAIRN = 'PN';
export const POLAND = 'PL';
export const PORTUGAL = 'PT';
// export const PUERTO_RICO = 'PR';
export const QATAR = 'QA';
// export const REUNION = 'RE';
export const ROMANIA = 'RO';
export const RUSSIAN_FEDERATION = 'RU';
// export const RWANDA = 'RW';
// export const SAINT_BARTHELEMY = 'BL';
// export const SAINT_HELENA = 'SH';
// export const SAINT_KITTS_AND_NEVIS = 'KN';
// export const SAINT_LUCIA = 'LC';
// export const SAINT_MARTIN = 'MF';
// export const SAINT_PIERRE_AND_MIQUELON = 'PM';
// export const SAINT_VINCENT_AND_GRENADINES = 'VC';
// export const SAMOA = 'WS';
// export const SAN_MARINO = 'SM';
// export const SAO_TOME_AND_PRINCIPE = 'ST';
export const SAUDI_ARABIA = 'SA';
// export const SENEGAL = 'SN';
export const SERBIA = 'RS';
// export const SEYCHELLES = 'SC';
// export const SIERRA_LEONE = 'SL';
export const SINGAPORE = 'SG';
// export const SINT_MAARTEN = 'SX';
// export const SLOVAKIA = 'SK';
export const SLOVENIA = 'SI';
// export const SOLOMON_ISLANDS = 'SB';
export const SOMALIA = 'SO';
export const SOUTH_AFRICA = 'ZA';
// export const SOUTH_GEORGIA_AND_SANDWICH_ISL = 'GS';
export const SOUTH_SUDAN = 'SS';
export const SPAIN = 'ES';
// export const SRI_LANKA = 'LK';
export const SUDAN = 'SD';
// export const SURINAME = 'SR';
// export const SVALBARD_AND_JAN_MAYEN = 'SJ';
// export const SWAZILAND = 'SZ';
export const SWEDEN = 'SE';
export const SWITZERLAND = 'CH';
export const SYRIAN_ARAB_REPUBLIC = 'SY';
export const TAIWAN = 'TW';
// export const TAJIKISTAN = 'TJ';
// export const TANZANIA = 'TZ';
export const THAILAND = 'TH';
// export const TIMOR_LESTE = 'TL';
// export const TOGO = 'TG';
// export const TOKELAU = 'TK';
// export const TONGA = 'TO';
// export const TRINIDAD_AND_TOBAGO = 'TT';
// export const TUNISIA = 'TN';
// export const TURKEY = 'TR';
// export const TURKMENISTAN = 'TM';
// export const TURKS_AND_CAICOS_ISLANDS = 'TC';
// export const TUVALU = 'TV';
// export const UGANDA = 'UG';
export const UKRAINE = 'UA';
export const UNITED_ARAB_EMIRATES = 'AE';
export const UNITED_KINGDOM = 'GB';
export const UNITED_STATES = 'US';
// export const UNITED_STATES_OUTLYING_ISLANDS = 'UM';
export const URUGUAY = 'UY';
// export const UZBEKISTAN = 'UZ';
// export const VANUATU = 'VU';
export const VENEZUELA = 'VE';
export const VIETNAM = 'VN';
// export const VIRGIN_ISLANDS_BRITISH = 'VG';
// export const VIRGIN_ISLANDS_US = 'VI';
// export const WALLIS_AND_FUTUNA = 'WF';
// export const WESTERN_SAHARA = 'EH';
export const YEMEN = 'YE';
// export const ZAMBIA = 'ZM';
export const ZIMBABWE = 'ZW';
