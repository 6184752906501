import { createContext, FC, ReactNode, useContext } from 'react';
import { isColorLight } from '~/app/lib/utils/color';
import { ItemContext } from '../../../types';
import Head from 'next/head';

interface PageThemeValue {
  backgroundColor: string;
  textColor: string;
  fontFamily?: string;
}

const DEFAULT_THEME = {
  backgroundColor: '#000',
  fontFamily: undefined,
  textColor: '#fff',
};

const PageThemeContext = createContext<PageThemeValue>(DEFAULT_THEME);

export const PageThemeProvider: FC<{
  itemContext: ItemContext | undefined;
  children: ReactNode;
}> = ({ itemContext, children }) => {
  const value = {
    ...DEFAULT_THEME,
    ...itemContext?.addons.THEME,
  };

  const textColor = isColorLight(value.backgroundColor) ? '#000' : '#fff';

  return (
    <PageThemeContext.Provider
      value={{
        ...value,
        textColor,
      }}
    >
      {children}
      {value.fontFamily && (
        <Head>
          <link
            // These font weights match up with the weights used in <Text>. If some fonts look to heavy/light
            // at these weights we could configure these on the per-font basis.
            href={`/api/fonts/css?family=${value.fontFamily}:wght@400;500;700&display=swap`}
            rel="stylesheet"
          />
        </Head>
      )}
    </PageThemeContext.Provider>
  );
};

export const usePageTheme = () => useContext(PageThemeContext);
