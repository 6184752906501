import React from 'react';

import { PageSectionComponent } from '../types';
import { InlineImageProps } from './types';
import Image from '~/app/components/Image';

const InlineImageSection: PageSectionComponent<InlineImageProps> = ({
  src,
  srcWidth,
  srcHeight,
  altText,
}) => {
  return (
    <Image
      src={src}
      size={[srcWidth, srcHeight]}
      alt={altText}
      testId="inlineImage"
    />
  );
};

export default InlineImageSection;
