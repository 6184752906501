import { PageSectionComponent } from '~/app/components/ItemPage/sections/types';
import { PageTitleText } from './components';
import { PageTitleSectionProps } from './types';
import ArtistNames from '../../ArtistNames';

const PageTitleSection: PageSectionComponent<PageTitleSectionProps> = ({
  layoutData,
  withArtistLinks,
}) => {
  const { item } = layoutData;
  const text = item.name;

  if (!text) return null;

  let subtitle: React.ReactNode | null = null;

  if ('artistIds' in item) {
    subtitle = (
      <ArtistNames
        artistIds={item.artistIds}
        withLinks={withArtistLinks}
        withStates={withArtistLinks}
      />
    );
  }

  return (
    <div data-testid="pageTitleSection">
      <PageTitleText text={text} subtitle={subtitle} />
    </div>
  );
};

export default PageTitleSection;
