import { FC } from 'react';
import { usePageTheme } from '~/app/components/ItemPage/ItemPageEdit/addons/theme/PageThemeContext';

import Text from '~/app/components/Text';
import { toRgba } from '~/app/lib/utils/color';

const IS_LONG_THRESHOLD = 22;

export const PageTitleText: FC<{
  text: string;
  subtitle?: string | React.ReactNode;
  padding?: string;
}> = ({ text, subtitle, children, ...textProps }) => {
  const textIsLong = text!.length > IS_LONG_THRESHOLD;
  const minFontSize = textIsLong ? '3.5rem' : '4.2rem';
  const maxFontSize = textIsLong ? '4.6rem' : '4.9rem';
  const baseSize = textIsLong ? '5vw' : '7vw';
  const pageTheme = usePageTheme();

  return (
    <Text
      size={baseSize}
      minSize={minFontSize}
      maxSize={maxFontSize}
      maxWidth="14em"
      isCentered
      family={pageTheme.fontFamily}
      {...textProps}
    >
      <Text
        testId="title"
        tag="h1"
        bold
        centered
        size="inherit"
        lineClamp={2}
        lineHeight={1.05}
        padding={`0 1rem`}
        shadow={`${toRgba(pageTheme.backgroundColor, 0.1)} 0 0 .05em,${toRgba(
          pageTheme.backgroundColor,
          0.2
        )} 0 .01em .1em`}
        opacity={0.9}
        style={{
          marginTop: '-.2em',
          ...toSpaceBelow(text),

          // we not using prettyWrap() here as it was causing bad wrapping for some long artist names
          // @ts-ignore
          textWrap: 'balance',
        }}
      >
        {text}
      </Text>
      {subtitle && (
        <Text
          size="0.62em"
          // reduce space under text from line-height so next section v-aligns correctly
          margin="0.18em 0 -0.3em"
          // padding="0 0 0.04em"
          testId="subtitle"
          color={pageTheme.textColor}
          lineHeight="1.3em"
          withEllipsis
          shadow={`${toRgba(pageTheme.backgroundColor, 0.3)} 0px 0px 0.05em`}
          centered
        >
          {subtitle}
        </Text>
      )}
      {children}
    </Text>
  );
};

/**
 * The space between the title and content below depends on the characters in the text.
 * For example when there are descenders the text looks visually closer to the content
 * underneath it so we need to increase the margin.
 */
const toSpaceBelow = (text: string) => {
  if (hasDescenders(text)) {
    return {
      marginBottom: '-0.02em',
      paddingBottom: '0.02em',
    };
  }

  const isAllCaps = text.toUpperCase() === text;

  if (isAllCaps) {
    return {
      marginBottom: '-0.02em',
      paddingBottom: '0.02em',
    };
  }

  return {
    marginBottom: '-0.2em',
    paddingBottom: '0.1em',
  };
};

function hasDescenders(text: string) {
  // Check if the text contains letters with descenders
  const descenders = /[gjpqy]/;
  return descenders.test(text);
}
