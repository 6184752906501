import { FC, useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';

import HorizontalScroller from '~/app/components/Scroller2/HorizontalScroller';
import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import Text from '~/app/components/Text';
import Box from '~/app/components/Box';
import { useItemPageNav } from './useItemPageNav';
import LazyHydrateDelay from '../../LazyHydrate/LazyHydrateDelay';
import { useLoadingObserver } from '../LoadingObserver';
import { toRgba } from '~/app/lib/utils/color';
import { usePageTheme } from '../ItemPageEdit/addons/theme/PageThemeContext';

export * from './useItemPageNav';

const ItemPageNavInternal: FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const scrollerElRef = useRef<HTMLDivElement>(null);
  const { getItems } = useItemPageNav();
  const items = (getItems && getItems()) ?? [];
  const { totalLoading } = useLoadingObserver();
  const pageTheme = usePageTheme();

  // if dynamic PageSections are still loading don't
  // reveal the partially populated navigation
  if (totalLoading) {
    return null;
  }

  const itemsTruncated = useMemo(() => {
    const totalItems = items.length;

    // don't bother rendering a nav if there are less than 3 sections
    if (totalItems < 3) {
      return;
    }

    // never render more than 3 nav items on small-screen to keep ui clean/uncluttered
    if (!isLargeScreen) {
      return items.slice(0, 3);
    }

    return items;
  }, [items, isLargeScreen]);

  if (!itemsTruncated) {
    return null;
  }

  return (
    <nav
      style={{ paddingBottom: '0.2rem', pointerEvents: 'all' }}
      data-testid="itemPageNav"
    >
      <HorizontalScroller
        margin="auto -1rem 0 -1rem"
        scrollerRef={scrollerElRef}
        gradientColor="mask"
        render={useCallback(
          ({ ref, isOverflowing }) => {
            return (
              <Box
                nodeRef={ref}
                tag="ul"
                height="5.1rem"
                style={{
                  display: 'inline-flex',
                  whiteSpace: 'nowrap',
                  minWidth: '100%',
                }}
                margin="0"
                padding={`.65rem ${
                  isOverflowing ? '2.4rem' : '1.2rem'
                } .4rem 1.4rem`}
                fullHeight
                centerContent
              >
                {itemsTruncated.map(({ id, text }) => {
                  return (
                    <Text
                      tag="li"
                      className={classNames('navItem')}
                      key={id}
                      family={pageTheme.fontFamily}
                      letterSpacing={0.02}
                      margin={isLargeScreen ? '0 .35em' : '0 .35em'}
                      size={isLargeScreen ? '1.6rem' : '1.52rem'}
                      noFlexShrink
                      noSelection
                      shadow={`0 0 0.23em ${toRgba(
                        pageTheme.backgroundColor,
                        0.7
                      )}`}
                    >
                      <a
                        href={`#${id}`}
                        onClick={(event) => {
                          // don't actually change the url as users will likely end up
                          // accidentally sharing page links with hashes
                          event.preventDefault();

                          document.getElementById(id)?.scrollIntoView({
                            behavior: 'smooth',
                          });
                        }}
                      >
                        {text}
                      </a>
                    </Text>
                  );
                })}
              </Box>
            );
          },
          [isLargeScreen, itemsTruncated]
        )}
      />
      <style jsx>{`
        nav :global(.navItem) {
          opacity: 0.85;
        }

        @media (pointer: fine) {
          nav :global(.navItem:hover) {
            opacity: 1;
          }
        }
      `}</style>
    </nav>
  );
};

const ItemPageNav = () => {
  return (
    // COMPLEX: ItemPageNav first render *must* run after all PageSections have
    // rendered so that they have registered their navItems. As all below fold
    // sections are hydrated lazily (after 500ms) we must also hydrate ItemPageNav
    // lazily to that it renders after. This is not applicable for ssr where
    // everything is rendered sync.
    <LazyHydrateDelay delay={600}>
      <ItemPageNavInternal />
    </LazyHydrateDelay>
  );
};

export default ItemPageNav;
