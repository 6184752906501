import React, { FC } from 'react';

import Clickable from '../Clickable';
import useSelectArtists from '~/app/lib/hooks/useSelectArtists';
import { toPublicEndpoint } from '~/app/lib/getPublicEndpoint';
import { MappedArtist } from '~/app/lib/songwhipApi/mapper';

type ArtistNamesProps = {
  artistIds: number[];
  withLinks?: boolean;
  withStates?: boolean;
};

const ArtistNames: FC<ArtistNamesProps> = ({
  artistIds,
  withLinks = false,
  withStates = false,
}) => {
  const artists = useSelectArtists(artistIds);

  return (
    <>
      {(artists?.filter(Boolean) as MappedArtist[]).map(
        ({ name, pagePath }, index) => (
          <>
            {index > 0 ? ', ' : ''}
            <Clickable
              testId="artistName"
              key={name}
              href={withLinks ? toPublicEndpoint(pagePath) : undefined}
              // must be 'inline' so ellipsis breaks on letter not block
              style={{
                display: 'inline',
                cursor: withStates ? 'pointer' : 'default',
              }}
              isInline
              title={name}
              targetBlank={false}
              withActiveStyle={withStates}
              withFocusStyle={withStates}
            >
              {name}
            </Clickable>
          </>
        )
      )}
    </>
  );
};

export default ArtistNames;
